import React from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../../../components/Layout';
import useWindowSize from '../../../helpers/useWindowSize';

const Blindflekk = ({ data }) => {
  const [width, height] = useWindowSize();
  return (
    <Layout>
      <section className='hundredikt'>
        <h1>blindflekk</h1>
        <h2>
          <Link to='/work'>2020/magazine</Link>
        </h2>

        <Img
          fluid={data.image1.childImageSharp.fluid}
          alt='front of rave magazine'
          className='topimage'
        />

        <div className='mid-section'>
          <Img
            fluid={data.image2.childImageSharp.fluid}
            alt='shot of opened rave magazine from top left'
            className='leftimage'
          />
          <p className='maintext'>
            «Blindflekk» is a new magazine concept that explores different
            sub-cultures of Norwegian society that are often overlooked.
            <br />
            Each number will take on a new sub-culture.
            <br />
            <br />
            Our produced example takes on the Norwegian rave culture.
          </p>
          <p className='extratext'>
            Design: Sarah Solheim
            <br />
            Writer: Jakob August Andresen
          </p>
          <Img
            fluid={data.image3.childImageSharp.fluid}
            alt='shot of multiple opened rave magazines from above'
            className='rightimage'
            style={{
              position: width > 1100 ? 'absolute' : 'relative',
            }}
          />
        </div>
        <div className='small-container'>
          <Img
            fluid={data.image4.childImageSharp.fluid}
            alt='page 4 of rave magazine'
          />
        </div>

        <div className='magasin'>
          <Img
            fluid={data.image5.childImageSharp.fluid}
            alt='page 4 of rave magazine'
          />
          <Img
            fluid={data.image6.childImageSharp.fluid}
            alt='page 5 of rave magazine'
          />
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query MyQuery {
    image1: file(relativePath: { eq: "blindflekk/1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image2: file(relativePath: { eq: "blindflekk/2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image3: file(relativePath: { eq: "blindflekk/3.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image4: file(relativePath: { eq: "blindflekk/4.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image5: file(relativePath: { eq: "blindflekk/Magasin4.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image6: file(relativePath: { eq: "blindflekk/Magasin5.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default Blindflekk;
